(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name persons.newPerson.controller:NewPersonCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.persons.newPerson')
    .controller('NewPersonCtrl', NewPersonCtrl)
    .directive('fileDropzone', function() {
  return {
    restrict: 'A',
    scope: {
      file: '=',
      fileName: '='
    },
    link: function(scope, element, attrs) {
      var checkSize,
          isTypeValid,
          processDragOverOrEnter,
          validMimeTypes;
      
      processDragOverOrEnter = function (event) {
        if (event !== null) {
          event.preventDefault();
        }
        event.dataTransfer.effectAllowed = 'copy';
        return false;
      };
      
      validMimeTypes = attrs.fileDropzone;
       
      checkSize = function(size) {
        var _ref;
        var maxima=2;
        
        if (((_ref = attrs.maxFileSize) === (void 0) || _ref === '') || (size / 1024) / 1024 < maxima) {
          return true;
        } else {
          alert("El fitxer no pot ser més gran de " + maxima + " MB");
          return false;
        }
      };

      isTypeValid = function(type) {
        if ((validMimeTypes === (void 0) || validMimeTypes === '') || validMimeTypes.indexOf(type) > -1) {
          return true;
        } else {
          alert("Tipus de fitxer invàlid. Hauria de ser: " + validMimeTypes);
          return false;
        }
      };
      
      element.bind('dragover', processDragOverOrEnter);
      element.bind('dragenter', processDragOverOrEnter);

      return element.bind('drop', function(event) {
            
        var file, name, reader, size, type;
        if (event !== null) {
          event.preventDefault();
        }
        reader = new FileReader();
        reader.onload = function(evt) {
          if (checkSize(size) && isTypeValid(type)) {
            return scope.$apply(function() {
              scope.file = evt.target.result;
              if (angular.isString(scope.fileName)) {
                return scope.fileName = name;
              }
            });
          }
        };
        file = event.dataTransfer.files[0];
        name = file.name;
        type = file.type;
        size = file.size;
       
        reader.readAsDataURL(file);
       
        return false;
      });
    }
  };
})
.directive("fileread", [function () {
    return {
        scope: {
            fileread: "="
        },
        link: function (scope, element, attributes) {
            element.bind("change", function (changeEvent) {
                var reader = new FileReader();
                reader.onload = function (loadEvent) {
                    scope.$apply(function () {
                        scope.fileread = loadEvent.target.result;
                       
                    });
                }; 
               
                if (((changeEvent.target.files[0].size)/1024/1024) < 2 ){
                    reader.readAsDataURL(changeEvent.target.files[0]);
                return true;
                }
                    else
                    {
                       
                         alert("El fitxer no pot ser més gran de 2 MB");
                         return false;
                    }
            });
        }
    };
}])
;


  function NewPersonCtrl($scope, $stateParams, $state, $mdToast, $filter, $mdDialog,
                         LicenseTeam, EntityTeams, entities, licenseTypes, genders, DuplicatedPerson, PersonLicense, sports) {
    var vm = this;
  $scope.image = null;
  $scope.imageFileName = '';
  
  $scope.uploadme = {};
  $scope.uploadme.src = '';
  
      vm.ctrlName = 'NewPersonCtrl';
    vm.licenseTeamTypes = _.filter(licenseTypes, function (lt) {
      return lt.team
    });
    vm.personGenders = _.filter(genders, function (g) {
      return g.person
    });
    vm.person = {};

    vm.entities = entities;
    vm.lodp = false;

    $scope.$watch('vm.person.entity', function (entity) {
      if (angular.isDefined(entity) && entity !== null) {
        EntityTeams.query({entityId: entity.id}, function (data) {
          vm.entityTeams = data;
        });
      }
    });

    var findItemById = function (collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    };

    if (entities.length === 1) {
      vm.person.entity = entities[0];
    } else if (angular.isDefined($stateParams.selectedEntity.id)) {
      vm.person.entity = findItemById(entities, $stateParams.selectedEntity.id);
    }

    if (angular.isDefined(vm.person.entity) && angular.isDefined($stateParams.selectedTeam.id)) {
      EntityTeams.query({entityId: vm.person.entity.id}, function (data) {
        vm.entityTeams = data;
        vm.person.team = findItemById(vm.entityTeams, $stateParams.selectedTeam.id);
      });
    }

    function parseDate(date) {
      return moment(date).format('YYYY-MM-DD');
    }

    vm.successfulPersonAddition = function(){
       
      $state.go('home.persons', {selectedEntity: vm.person.entity, selectedTeam: vm.person.team}, {reload: true});
      $mdToast.show(
        $mdToast.simple()
          .textContent($filter('translate')('PEOPLE.SUCCESSFUL_CREATE'))
          .position('bottom left')
          .hideDelay(3000)
      );
    };

    vm.errorInPersonAddition = function(err){
      var errorMessage;
      if (err.status === 400) {
        errorMessage = err.data.message || 'BAD_REQUEST_PERSON';
      } else if (err.status === 409) {
        errorMessage = 'PEOPLE.EXISTING_PERSON';
      } else {
        errorMessage = 'SERVER_ERROR';
      }
      $mdToast.show(
        $mdToast.simple()
          .textContent($filter('translate')(errorMessage))
          .position('bottom left')
          .hideDelay(3000)
      );
    };

    vm.createPerson = function () {
      var newLicense = {
        licenceTypeId: vm.person.licenseType,
        person: {
          name: vm.person.name,
          surname: vm.person.surname,
          secondSurname: vm.person.secondSurname,
          genderId: vm.person.genderId,
          bornDate: parseDate(vm.person.birthday),
          nif: vm.person.nif,
          healthInsuranceCardNumber: vm.person.healthInsuranceCard,
          email: vm.person.email,
          address: vm.person.address,
          town: vm.person.town,
          zipCode: vm.person.zipCode
        },foto:$scope.image
      };

   LicenseTeam.save({teamId: vm.person.team.id}, newLicense, vm.successfulPersonAddition, vm.errorInPersonAddition);
    
    };

    vm.assignExistentPerson = function (personId) {
      var data = {licenceTypeId: vm.person.licenseType, teamId: vm.person.team.id};
      PersonLicense.save({personId: personId}, data, vm.successfulPersonAddition, vm.errorInPersonAddition);
    };

    vm.savePerson = function () {
      DuplicatedPerson.query({
        name: vm.person.name,
        surname: vm.person.surname,
        bornDate: parseDate(vm.person.birthday)
      }, function (data) {
        if (data.length > 0) {
          $mdDialog.show({
            controller: 'DuplicateDialogCtrl',
            templateUrl: 'home/persons/new-person/duplicate-dialog.tpl.html',
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            locals: {
              duplicatedPersons: data,
              licenses: licenseTypes,
              sports: sports
            }
          }).then(function (personId) {
            if (angular.isDefined(personId)) {
              vm.assignExistentPerson(personId);
            } else {
              vm.createPerson();
            }
          });
        } else {
          vm.createPerson();
        }
      }, function (err) {
      });
      
    };
  }
}());
